import React from 'react'
import { Container, Flex, Link as A, Text } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  FaqHeader,
  CareerRow,
  contentContainer,
  content,
  CareerSectionLead,
  PrivacyBackground
} from 'components/Content'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

A.link = A.withComponent(Link)

const title = 'Refund and Cancellation Policy - Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/refund/"
    img="/cards/workshops.png"
  >
    <Nav />
    <Social />
    <PrivacyBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[3, 4]} mb={[4, 5]} mx="auto">
            Refund and Cancellation Policy
          </Headline>
        </Container>
      </Flex>
    </PrivacyBackground>
    <Container {...contentContainer}>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="fuschia.5">
          <strong>Refunds:</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        For training programs offered by Coding Sastra Offline or Online, a user
        may request a refund 3 days before their chosen batch commencement date.
        A transaction processing charge of 20% of the training fee paid by the
        user will be deducted and the remaining will be refunded within 15
        working days (Monday to Friday, excluding national holidays) of refund
        request being raised by the user. However, once the user has made the
        payment and accessed the training content, no refund would be permitted.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="fuschia.5">
          <strong>Cancellation:</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        If a user is enrolled in a training that has been withdrawn by Coding
        Sastra, he/she will be entitled to 100% refund within 15 working days
        (Monday to Friday, excluding national holidays) of the training program
        being withdrawn and the refund would be initiated by Coding Sastra.
      </CareerSectionLead>
      <CareerSectionLead>
        If a user is enrolled in a training that has been withdrawn by Coding
        Sastra, he/she will be entitled to 100% refund within 15 working days
        (Monday to Friday, excluding national holidays) of the training program
        being withdrawn and the refund would be initiated by Coding Sastra.
      </CareerSectionLead>
      <CareerSectionLead>
        We may make changes to our products & services, and associated terms &
        conditions (including terms and conditions on refund and cancellation),
        from time to time. A user is advised to refer to our latest terms and
        conditions on this page and associated product/service specific terms
        and conditions page before making a purchase.
      </CareerSectionLead>
    </Container>
    <Footer dark />
  </Layout>
)

export const query = graphql`
  query refundMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
